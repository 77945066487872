import { Flex, View } from "@aws-amplify/ui-react";
import { withAuth } from "../context/auth/AuthProvider";
import { Availability } from "../components/Availability";
import { FC } from "react";

interface ProfilePageProps {
}

export const ProfilePage: FC<ProfilePageProps> = withAuth((props) => {
  return (
    <Flex direction="column" gap="1rem">
      <View padding="medium">
        <Availability />
      </View>
    </Flex>
  );
})