import { API } from "aws-amplify";
import { CT_API_NAME } from "../../helpers/api";

export const postCtApiLogin = async (username: string, password: string) => {
  const result = await API.post(CT_API_NAME, '/login', {
    headers: {
      accept: 'application/json',
      'Content-Type': 'application/json',
    },
    withCredentials: true,
    body: {
      username,
      password,
    },
  });

  return result;
};
