import { Badge, Collection, Heading, View } from "@aws-amplify/ui-react";
import { IEventRequest, getPersonsConfirmedRequests, getPersonsOpenRequests } from "../helpers/sunday"
import { RequestCard } from "./RequestCard";
import { flow } from "lodash/fp";
import { getLength } from "../helpers/functional";
import { logger } from "../context/aux/logging";

interface RequestsCollectionProps {
  confirmed: boolean;
  title?: string;
  requests: IEventRequest[];
  currentUserPersonId?: number;
}

const byConfirmed = (confirmed: boolean, personId: number | null) => (req: IEventRequest) => req.confirmed === confirmed && req.personId === personId;
const openRequests = (confirmed: boolean, personId: number | null) => (requests: IEventRequest[]) => requests.filter(byConfirmed(confirmed, personId));
const numberOfOpenRequests = (confirmed: boolean, personId: number | null, requests: IEventRequest[]) => flow(
  openRequests(confirmed, personId),
  getLength,
)(requests);

export const RequestsCollection = ({confirmed, title, currentUserPersonId, requests}: RequestsCollectionProps) => {
  logger.info("RequestsCollection", {confirmed, title, currentUserPersonId, requests});

  if (confirmed && getPersonsConfirmedRequests(currentUserPersonId || null, true)(requests).length === 0) return <></>
  if (!confirmed && getPersonsOpenRequests(currentUserPersonId || null, true)(requests).length === 0) return <></>
  
  return (
    <View paddingBottom="medium">
      {title && (
        <Heading level={5} paddingBottom="small">
          {title} {!confirmed && numberOfOpenRequests(confirmed, currentUserPersonId || null, requests) > 0 && (
            <Badge variation="warning" size="small">{numberOfOpenRequests(confirmed, currentUserPersonId || null, requests)}</Badge>
          )}
        </Heading>
      )}
      <Collection type="list" items={requests.filter(byConfirmed(confirmed, currentUserPersonId || null))}>
        {(request, index) => (
          <RequestCard key={index} request={request} otherContributors={getPersonsConfirmedRequests(currentUserPersonId || null, false)(requests)} />
        )}
      </Collection>
    </View>
  )
}