import { FC } from 'react';
import { BrowserRouter as Router, Route, Routes, Navigate } from 'react-router-dom';
import { MainPage } from './pages/MainPage';
import { withSentryReactRouterV6Routing } from '@sentry/react';

const SentryRoutes = withSentryReactRouterV6Routing(Routes);

const App: FC = () => (
  <Router>
    <SentryRoutes>
      <Route path="/" element={<Navigate to="/home" />} />
      <Route path="/home" element={<MainPage initialTab={0} />} />
      <Route path="/team" element={<MainPage initialTab={1} />} />
      <Route path="/profile" element={<MainPage initialTab={2} />} />
      <Route path="*" element={<Navigate to="/home" />} />
    </SentryRoutes>
  </Router>
);

export default App;
