import React, { useEffect } from 'react';
import ReactDOM from 'react-dom/client';
import { ThemeProvider } from '@aws-amplify/ui-react';
import './index.css';
import "@aws-amplify/ui-react/styles.css";
import { studioTheme } from './ui-components';
import '@fontsource-variable/inter';
import App from './App';
import { Amplify } from 'aws-amplify';
import awsconfig from './aws-exports';
import reportWebVitals from './reportWebVitals';
import { AuthProvider } from './context/auth/AuthProvider';
import { LanguageProvider } from './context/aux/LanguageContext';
import { BrowserTracing, ErrorBoundary, Replay, init, reactRouterV6Instrumentation } from '@sentry/react';
import { createRoutesFromChildren, matchRoutes, useLocation, useNavigationType } from 'react-router-dom';
import { CookieUniversalProvider } from '@shopify/react-cookie';

Amplify.configure(awsconfig);

// Inititialize Sentry
init({
  dsn: "https://8314808242ee0f506dbafb7b8550f12e@o4505692733964288.ingest.sentry.io/4505692737044480",
  environment: process.env.NODE_ENV,
  integrations: [
    new BrowserTracing({
      // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
      tracePropagationTargets: [
        "localhost",
        "dev.msk.cabcookie.de",
        "msk.cabcookie.de",
      ],
      routingInstrumentation: reactRouterV6Instrumentation(
        useEffect,
        useLocation,
        useNavigationType,
        createRoutesFromChildren,
        matchRoutes,
      ),
    }),
    new Replay(),
  ],
  // Performance Monitoring
  tracesSampleRate: process.env.NODE_ENV === 'development' ? 1.0 : 1.0, // 1.0 => Capture 100% of the transactions
  replaysSessionSampleRate: process.env.NODE_ENV === 'development' ? 1.0 : 0.1,
  replaysOnErrorSampleRate: 1.0,
});

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);
root.render(
  <React.StrictMode>
    <CookieUniversalProvider>
      <ThemeProvider theme={studioTheme}>
        <LanguageProvider>
          <AuthProvider>
            <ErrorBoundary>
              <App />
            </ErrorBoundary>
          </AuthProvider>
        </LanguageProvider>
      </ThemeProvider>
    </CookieUniversalProvider>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
