import { CookieSerializeOptions } from "@shopify/react-cookie";

export const Duration = (() => {
  const seconds = (sec: number = 1) => sec;
  const minutes = (min: number = 1) => min * 60;
  const hours = (hrs: number = 1) => hrs * 60 * 60;
  const days = (d: number = 1) => d * 24 * 60 * 60;
  const weeks = (w: number = 1) => w * 7 * 24 * 60 * 60;

  const inMilliSeconds = {
    seconds: (s: number = 1) => seconds(s) * 1000,
    minutes: (m: number = 1) => minutes(m) * 1000,
    hours: (h: number = 1) => hours(h) * 1000,
    days: (d: number = 1) => days(d) * 1000,
    weeks: (w: number = 1) => weeks(w) * 1000,
  };

  return {
    ...inMilliSeconds,
    inMilliSeconds,
    inSeconds: { minutes, hours, days, weeks },
    inMinutes: {
      seconds: (s: number = 1) => seconds(s) * 60,
      minutes: (m: number = 1) => minutes(m) * 60,
      hours: (h: number = 1) => hours(h) * 60,
      days: (d: number = 1) => days(d) * 60,
      weeks: (w: number = 1) => weeks(w) * 60,
    },
  }
})();

type SetCookieFunction = (value?: string, options?: CookieSerializeOptions) => void;
type SetCookieWithOptions = (setCookieFn: SetCookieFunction, options?: CookieSerializeOptions) => (value: string) => void;

export const setCookieWithOptions: SetCookieWithOptions = (setFn, options = {
  expires: addWeeks(4)(new Date()),
  maxAge: Duration.inSeconds.weeks(4),
}) => (value) => value === '{}' ?
  (() => setFn())() :
  (() => setFn(value, options))();

export const logError = (...args: any[]) => (bypassObject: any) => {
  console.error(...args, bypassObject);
  return bypassObject;
}

export const logInfo = (...args: any[]) => (bypassObject: any) => {
  console.info(...args, bypassObject);
  return bypassObject;
}

export const makeFunctionalLogger = (logger: (...args: any) => void) => (...args: any[]) => (bypassObject: any) => {
  logger(...args, bypassObject);
  return bypassObject;
}

export const log = (...args: any[]) => (bypassObject: any) => {
  console.log(...args, bypassObject);
  return bypassObject;
}

export const makeString = (input: any) => {
  if (typeof input === 'string') return input;
  if (typeof input === 'object') return JSON.stringify(input, null, 2);
  return String(input);
}

export const getLength = (arr: any[]) => arr.length;
export const makeDate = (date: string) => new Date(date);

export const toLocaleString = (locales?: Intl.LocalesArgument, options?: Intl.DateTimeFormatOptions | undefined) => (date: Date) => date.toLocaleString(locales, options);

export const longDateOptions: Intl.DateTimeFormatOptions = {
  weekday: 'long',
  day: 'numeric',
  month: 'long',
  year: 'numeric',
  hour: 'numeric',
  minute: 'numeric',
};

export const shortDateOptions: Intl.DateTimeFormatOptions = {
  weekday: 'short',
  day: 'numeric',
  month: 'short',
  year: 'numeric',
};

export const timeOptions: Intl.DateTimeFormatOptions = {
  hour: 'numeric',
  minute: 'numeric',
};

export const prependStr = (str: string) => (str2: string) => `${str}${str2}`;
export const appendStr = (str: string) => (str2: string) => `${str2}${str}`;

export const addDays = (days: number) => (date: Date) => {
  date.setDate(date.getDate() + days);
  return date;
}

export const addWeeks = (weeks: number) => addDays(7 * weeks);
export const toISOString = (date: Date) => date.toISOString();
export const substring = (start: number, end: number) => (str: string) => str.substring(start, end);
export const setToTimeOfDay = (timeOfDay: string) => (date: string) => new Date(`${date}T${timeOfDay}:00.000Z`);
