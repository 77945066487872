import { ICreateChange, IDatabaseChange, IDeleteChange, IUpdateChange } from "dexie-observable/api";
import { IEventRequest } from "../../helpers/sunday";
import { flow, filter } from "lodash";
import { getLength } from "../../helpers/functional";
import { LoggerFunction } from "../aux/logging";

export const DatabaseChangeType = {
  Create: 1,
  Update: 2,
  Delete: 3,
}

/**
 * Compares 2 arrays (server state and state of local database) and creates a
 * set of deletes that needs to be applied to the local database so it is in
 * sync with the server again.
 * 
 * @param tableName The name of the table name in the local database where the
 *                  change set is created for.
 * @param serverArr The state of the table according to the server.
 * @param localArr The state of the table in the local database.
 * @returns the change set of deletes
 */
const createDeleteChangeSet = (
  tableName: string,
  serverArr: IEventRequest[],
  localArr: IEventRequest[]
): IDeleteChange[] => {

  const deletes: IDeleteChange[] = [];

  localArr.forEach(localObj => {
    const matchingServerObj = serverArr.find(serverObj =>
      localObj.requestId === serverObj.requestId);

    if (!matchingServerObj) {
      const change: IDeleteChange = {
        type: DatabaseChangeType.Delete,
        table: tableName,
        key: localObj.requestId,
        oldObj: localObj,
      };
      deletes.push(change);
    }
  });

  return deletes;
}

/**
 * Compares 2 arrays (server state and state of local database) and creates a
 * set of create statements that needs to be applied to the local database so it
 * is in sync with the server again.
 * 
 * @param tableName The name of the table name in the local database where the
 *                  change set is created for.
 * @param serverArr The state of the table according to the server.
 * @param localArr The state of the table in the local database.
 * @returns the change set of create statements
 */
const createCreatesChangeSet = (
  tableName: string,
  serverArr: IEventRequest[],
  localArr: IEventRequest[]
): ICreateChange[] => {

  const creates: ICreateChange[] = [];

  serverArr.forEach(serverObj => {
    const matchingLocal = localArr.find(localObj => localObj.requestId === serverObj.requestId);
    if (!!matchingLocal) return;

    const change: ICreateChange = {
      type: DatabaseChangeType.Create,
      table: tableName,
      key: serverObj.requestId,
      obj: serverObj,
    };
    creates.push(change);
  });

  return creates;
}

/**
 * Compares 2 arrays (server state and state of local database) and creates a
 * set of updates that needs to be applied to the local database so it
 * is in sync with the server again.
 * 
 * @param tableName The name of the table name in the local database where the
 *                  change set is created for.
 * @param serverArr The state of the table according to the server.
 * @param localArr The state of the table in the local database.
 * @returns the change set of updates
 */
const createUpdatesChangeSet = (
  tableName: string,
  serverArr: IEventRequest[],
  localArr: IEventRequest[]
): IUpdateChange[] => {

  const updates: IUpdateChange[] = [];

  const createChangeSet = (
    newVal: any,
    oldVal: any,
  ): {[key: string]: any | undefined} =>
    Array.isArray(newVal) ?
      createArrayChangeSet(newVal, oldVal) :
      newVal instanceof Date && oldVal instanceof Date ?
        createDateChangeSet(newVal, oldVal) :
        typeof newVal === 'object' && newVal !== null ?
          generateUpdatesChangeSet(newVal, oldVal) :
          newVal !== oldVal ?
            newVal :
            undefined;
  
  const createDateChangeSet = (
    newDate: Date,
    oldDate: Date,
  ): Date | undefined =>
    newDate.getTime() !== oldDate.getTime() ?
      newDate :
      undefined;

  const createArrayChangeSet = (
    newArr: any[],
    oldArr: any[],
  ) => {

    const mods: {[key: string]: any | undefined} = {};
    newArr.forEach((newVal, index) => {
      const oldVal = oldArr[index];
      mods[index] = createChangeSet(newVal, oldVal);
    });
    return flow(
      filter((mod: any) => !!mod && JSON.stringify(mod) !== '{}'),
      getLength,
    )(mods) > 0 ? mods : undefined;
  }

  const generateUpdatesChangeSet = (
    newObj: any,
    oldObj: any,
  ): {[key: string]: any | undefined} => {

    const mods: {[key: string]: any | undefined} = {};
    
    for (const key in newObj) {
      if (newObj.hasOwnProperty(key)) {
        const newVal = newObj[key];
        const oldVal = oldObj[key];
        const changeSet = createChangeSet(newVal, oldVal);
        if (!!changeSet) mods[key] = changeSet;
      }
    }
    return mods;
  }

  serverArr.forEach(serverObj => {
    const matchingLocal = localArr.find(localObj =>
      localObj.requestId === serverObj.requestId);

    if (!matchingLocal) return;

    const mods = generateUpdatesChangeSet(serverObj, matchingLocal);
    if (Object.keys(mods).length === 0) return;

    const change: IUpdateChange = {
      type: DatabaseChangeType.Update,
      table: tableName,
      key: serverObj.requestId,
      mods: mods,
      oldObj: matchingLocal,
      obj: serverObj,
    };
    updates.push(change);

  });

  return updates;
}

/**
 * Compares 2 arrays (server state and state of local database) and creates a
 * set of changes that needs to be applied to the local database so it is in
 * sync with the server again.
 * 
 * @param tableName The name of the table name in the local database where the
 *                  change set is created for.
 * @param serverArr The state of the table according to the server.
 * @param localArr The state of the table in the local database.
 * @returns the change set
 */
export const createChangeSet = (
  tableName: string,
  serverArr: IEventRequest[],
  localArr: IEventRequest[],
  logger: LoggerFunction,
): IDatabaseChange[] => {
  logger('Creating change set...');

  const deletes: IDeleteChange[] = createDeleteChangeSet(tableName, serverArr, localArr);
  const creates: ICreateChange[] = createCreatesChangeSet(tableName, serverArr, localArr);
  const updates: IUpdateChange[] = createUpdatesChangeSet(tableName, serverArr, localArr);

  logger('count of changes', {
    create: creates.length,
    update: updates.length,
    delete: deletes.length,
  });


  return [...deletes, ...creates, ...updates];
}

