import { useState } from "react";
import { IEventRequest, makeTimesList, statusToColor, statusToName } from "../helpers/sunday"
import { Text, View } from "@aws-amplify/ui-react";
import { flow } from "lodash/fp";
import { appendStr, prependStr } from "../helpers/functional";
import { AcceptDeclineButton, DeclineCommentTextField, declineRequest } from "./AcceptDeclineButtons";
import { useLanguage } from "../context/aux/LanguageContext";

interface EventCardProps {
  request: IEventRequest;
  currentUserPersonId?: number;
}

export const EventCard = ({request, currentUserPersonId}: EventCardProps) => {
  const [isLoading, setIsLoading] = useState(false);
  const [showCommentField, setShowCommentField] = useState(false);
  const [comment, setComment] = useState('');
  const { language } = useLanguage();
  
  return (
    <View>
      {request.personId === currentUserPersonId ? (<>
        <Text fontWeight={600} color={statusToColor(request)}>
          {request.positionName}
          {flow(
            makeTimesList(language),
            prependStr(' ('),
            appendStr(')'),
          )(request)}
          {' '}
          <AcceptDeclineButton
            paddingTop='xxxs'
            hideText
            paddingLeft='xxxs'
            onClick={() => setShowCommentField(true)}
            isLoading={isLoading}
            category="decline"
          />
        </Text>
        {showCommentField && (
          <DeclineCommentTextField
            declineRequest={declineRequest(
              setIsLoading,
              () => setShowCommentField(false),
              request,
              comment,
            )}
            setComment={setComment}
          />
        )}
        
        </>) : (<>
        <Text fontSize='small' color={statusToColor(request)}>
          {statusToName(request)}
          {flow(
            makeTimesList(language),
            prependStr(' ('),
            appendStr(')'),
          )(request)}
        </Text>
        <Text fontSize='small'>
          {request.positionName}
        </Text>
      </>)}
    </View>
  );
}