import { Collection, Flex, ScrollView, View } from "@aws-amplify/ui-react";
import { IEventRequest } from "../helpers/sunday";
import { filter, flow, isEqual, map, uniq, uniqWith } from "lodash/fp";
import { EventTeamCard, GroupEventDaysWithDetails } from "../components/EventTeamCard";
import { ReactNode } from "react";
import { useLiveQuery } from "dexie-react-hooks";
import { createLogger } from "../context/aux/logging";
import { ctDb } from "../context/data/db";
import { sortBy } from "lodash";

interface TeamPageProps {
  children?: ReactNode;
}

const groupForEventDays = (events: IEventRequest[]): GroupEventDaysWithDetails[] => flow(
  map(({eventDay}: IEventRequest) => eventDay),
  uniq,
  map((eventDay: string) => ({
    eventDay,
    eventDetails: flow(
      filterByEventDay(eventDay),
      map(({eventStartDate, eventName, eventDescription}: IEventRequest) => ({
        eventStartDate: new Date(eventStartDate),
        eventName,
        eventDescription,
      })),
      uniqWith(isEqual),
    )(events),
    })),
)(events)

const filterByEventDay = (eventDay: string) => filter((event: IEventRequest) => event.eventDay === eventDay);

export const TeamPage = ({children}: TeamPageProps) => {
  const logger = createLogger('TeamPage', true);
  
  const events = useLiveQuery(async () => {
    const result = await ctDb.events.toArray();
    logger('useLiveQuery()', 'result', result);
    return sortBy(result, 'eventStartDate', 'positionId');
  });

  return (
    <Flex direction='column' gap='1rem'>
      <View padding='medium'>
        <ScrollView width='100%' maxHeight='89vh'>
          <Collection type="list" items={groupForEventDays(events || [])}>
            {(event: GroupEventDaysWithDetails, index: number) => (
              <EventTeamCard key={index} event={event} requests={filterByEventDay(event.eventDay)(events)} />
            )}
          </Collection>
        </ScrollView>
      </View>
      {children}
    </Flex>
  )
};
