import { FC, useState } from 'react';
import { IEventRequest, filterByDay, isPending, makeTimesList, statusToColor } from '../helpers/sunday';
import { Card, Grid, Heading, Text, View } from '@aws-amplify/ui-react';
import { flow, get, join, map } from 'lodash/fp';
import { appendStr, longDateOptions, prependStr, shortDateOptions, toLocaleString } from '../helpers/functional';
import { AcceptDeclineButtons } from './AcceptDeclineButtons';
import { ExpandIcon } from './ExpandIcon';
import { useLanguage } from '../context/aux/LanguageContext';

interface RequestCardProps {
  request: IEventRequest;
  otherContributors: IEventRequest[];
  isExpanded?: boolean;
}

export const RequestCard: FC<RequestCardProps> = ({request, otherContributors, isExpanded = false}) => {
  const { language } = useLanguage();
  const [detailsExpanded, setDetailsExpanded] = useState(isExpanded);
  const mapOtherContributors = flow(
    filterByDay(request.eventDay),
    map((othersReq: IEventRequest) => `${othersReq.personName} - ${othersReq.positionName} (${makeTimesList(language)(othersReq)})`),
  );

  return (
    <Card
      borderRadius="small"
      borderColor="lightgray"
      borderWidth="1px"
    >
      <Heading level={6} color={statusToColor(request)}>
        {flow(
          get('eventStartDate'),
          toLocaleString(language, shortDateOptions),
        )(request)}
        {flow(
          makeTimesList(language),
          prependStr(' ('),
          appendStr(')'),
        )(request)}
        {` – ${request.positionName}`}
        {isPending(request) && '?'}
      </Heading>
      
      <View onClick={() => setDetailsExpanded(!detailsExpanded)} marginTop='medium' marginBottom='medium'>
        <Grid templateColumns='1fr 20fr' alignItems='flex-start'>
          <View>
            <ExpandIcon expanded={detailsExpanded} />
          </View>

          <Text fontSize='small' isTruncated>
            <b>Details{request.confirmed && ' and options'}: </b>
            {!detailsExpanded && flow(
              mapOtherContributors,
              join(', '),
            )(otherContributors)}
          </Text>

          {detailsExpanded && mapOtherContributors(otherContributors).map((detail: string, index: number) => (
            <Text fontSize='small' column={2} key={index}>{detail}</Text>
          ))}

          {detailsExpanded && !request.confirmed && (
            <Text column={2} fontSize='small' marginTop='small'>
              Requested on: {flow(
                get('requestedDate'),
                toLocaleString(language, longDateOptions),
              )(request)}
            </Text>
          )}

          {detailsExpanded && request.confirmed && (
            <View column={2}>
              <AcceptDeclineButtons request={request} />
            </View>
          )}
        </Grid>
      </View>

      {!request.confirmed && <AcceptDeclineButtons request={request} />}
      
    </Card>
  )
}
