import { ComponentType, Dispatch, FC, ReactNode, SetStateAction, createContext, useContext } from 'react';
import { LoginForm } from '../../components/LoginForm';
import { handleError } from '../../helpers/handle-errors';
import { postCtApiLogin } from './handle-login';
import { logger, loggerWithSetState } from '../aux/logging';
import { BrowserCookieManager, useCookie } from '@shopify/react-cookie';
import { flow } from 'lodash/fp';
import { setCookieWithOptions } from '../../helpers/functional';
import { setUser } from '@sentry/react';

type LoginResult = {
  userData: UserData;
  token: string;
}

export type UserData = {
  personId: number;
  firstName: string;
  lastName: string;
  nickname: string;
  email: string;
  imageUrl: string;
}

export type AuthContextType = {
  isAuthenticated: boolean;
  token: string | undefined;
  getUserData: () => UserData;
  login: (
    username: string,
    password: string,
    setLoginErrorMessage: Dispatch<SetStateAction<string>>,
  ) => void;
  logout: (
    setLogoutErrorMessage: Dispatch<SetStateAction<string>>,
  ) => void;
};

const AuthContext = createContext<AuthContextType>({
  isAuthenticated: true,
  getUserData: () => ({} as UserData),
  token: undefined,
  login: (
    username: string,
    password: string,
    setLoginErrorMessage: Dispatch<SetStateAction<string>>,
  ) => {},
  logout: (
    setLogoutErrorMessage: Dispatch<SetStateAction<string>>,
  ) => {},
});

export const useAuth = () => useContext(AuthContext);

type SetUserData = (userData: UserData) => void;

interface AuthProviderProps {
  children?: ReactNode;
}

export const getUserDataWithoutProvider = (): UserData => {
  const cookie = new BrowserCookieManager().getCookie('userData');
  return cookie ? JSON.parse(cookie) : {};
}

export const getTokenWithoutProvider = (): string => {
  const cookie = new BrowserCookieManager().getCookie('token');
  return cookie || '';
}

export const AuthProvider: FC<AuthProviderProps> = ({ children }) => {
  const [userDataCookie, setUserDataCookie] = useCookie('userData');
  const [tokenCookie, setTokenCookie] = useCookie('token');

  const getUserData = (): UserData => userDataCookie ? JSON.parse(userDataCookie) : {} as UserData;
  
  const setUserData: SetUserData = flow(
    JSON.stringify,
    setCookieWithOptions(setUserDataCookie),
  );
  
  const { personId } = getUserData();

  const handleLogout = async (setErrorMessage: Dispatch<SetStateAction<string>>) => {
    setUserData({} as UserData);
    setUser(null);
    setTokenCookie();
  };

  const handleLogin = async (
    username: string,
    password: string,
    setLoginErrorMsg: Dispatch<SetStateAction<string>>,
  ) => {
    logger.info('handleLogin: Attempting login with username:', username);

    try {
      const result: LoginResult = await postCtApiLogin(username, password);
      logger.info('handleLogin Result:', result);
      const {userData, token} = result;
      if (!(userData.personId)) {
        setLoginErrorMsg(JSON.stringify(result));
        return;
      }

      setUserData(userData);
      setLoginErrorMsg('');
      setTokenCookie(token);
      setUser({username: `${userData.personId}`})
      return 'success';
    } catch (error) {
      return handleError(error, '', () => {}, loggerWithSetState(setLoginErrorMsg));
    }
  };
  
  return (
    <AuthContext.Provider value={{
      isAuthenticated: !!tokenCookie && !!personId,
      token: tokenCookie,
      getUserData,
      login: handleLogin,
      logout: handleLogout,
    }}>
      {children}
    </AuthContext.Provider>
  );
};

export const withAuth = <P extends object>(WrappedComponent: ComponentType<P>) => {
  const AuthenticatedComponent: FC<P> = (props) => {
    const { isAuthenticated } = useAuth();
    if (!isAuthenticated) return <LoginForm />;
    return <WrappedComponent {...props} />;
  };

  return AuthenticatedComponent;
};
