import { Heading } from "@aws-amplify/ui-react";
import { FC, createContext, useEffect, useState } from "react";
import { IAvailability, apiChurchToolsAbsences } from "../helpers/profile";
import { useAuth } from "../context/auth/AuthProvider";
import { Duration } from "../helpers/functional";

export const RefreshAvailabilityDataContext = createContext(() => {});

interface AvailabilityProps {
}  

export const Availability: FC<AvailabilityProps> = () => {
  const [availabilities, setAvailabilities] = useState([] as IAvailability[])
  const { logout } = useAuth();
  const { getUserData } = useAuth();
  const { personId } = getUserData();

  const refreshData = () => {
    apiChurchToolsAbsences(setAvailabilities, personId, logout);
  };

  useEffect(() => {
    refreshData();
    const interval = setInterval(refreshData, Duration.minutes(5));
    return () => {
      clearInterval(interval);
    };
  }, []);

  if (!personId) return <></>

  return (
    <RefreshAvailabilityDataContext.Provider value={refreshData}>
      <Heading level={5}>
        My absence
      </Heading>

    </RefreshAvailabilityDataContext.Provider>
  );
}