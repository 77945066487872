import { API } from "aws-amplify";
import { compact, concat, filter, flow, get, join, map } from "lodash/fp";
import { Dispatch, SetStateAction } from "react";
import { AxiosError, isAxiosError } from "axios";
import { Duration, timeOptions, toLocaleString } from "./functional";
import { CT_API_NAME } from "./api";

const notKeyServices =  [7, 64, 76, 70, 79, 100];
const positionsSpanServices = [6, 64, 73, 76, 79];

export const getRequestsDevData = (setRequests: Dispatch<SetStateAction<IEventRequest[]>>) => {
  flow(
    map(fixDatesInRequest),
    setRequests,
  )(require('./example-requests.json'));
  return;
}

export interface IEvent {
  eventId: number;
  eventName: string;
  eventDescription: string;
  eventStartDate: Date;
  eventDay: string;
  linkedEvents?: IEvent[];
}

export interface IRequest extends IEvent {
  requestId: number;
  confirmed: boolean;
  requestedDate: Date;
  linkedRequests?: IRequest[];
}

export interface IEventRequest extends IRequest {
  personName?: string;
  personId?: number;
  positionId: number;
  positionGroupId: number;
  positionName: string;
  isKeyPosition?: boolean;
}

interface IEventServiceChurchToolsApi {
  agreed: boolean;
  id: number;
  isValid: boolean;
  name?: string;
  personId?: number;
  requestedDate: string;
  service: string;
  serviceId: number;
}

export interface IEventChurchToolsApi {
  description: string;
  eventServices: IEventServiceChurchToolsApi[];
  id: number;
  name: string;
  serviceGroupId?: number;
  startDate: string;
}

export const eventWithin7Days = ({eventDay}: IEventRequest) => {
  // console.log('eventWithin7Days', eventDay);
  const diff = (new Date(eventDay).getTime() - new Date().getTime()) / Duration.days();
  return diff >= -1 && diff <= 6;
}

export const isPending = ({personName, confirmed}: IEventRequest) => !!personName && !confirmed;

export const makeEventObj = ({
  eventId,
  eventName,
  eventDescription,
  eventStartDate,
  eventDay,
  linkedRequests,
}: IEventRequest): IEvent => ({
  eventId,
  eventName,
  eventDescription,
  eventStartDate,
  eventDay,
  linkedEvents: flow(
    map(makeEventObj),
  )(linkedRequests)
});

const makeRequestObj = ({
  eventId,
  eventName,
  eventDescription,
  eventStartDate,
  eventDay,
  requestId,
  confirmed,
  requestedDate,
  linkedRequests,
}: IEventRequest): IRequest => ({
  eventId,
  eventName,
  eventDescription,
  eventStartDate,
  eventDay,
  requestId,
  confirmed,
  requestedDate,
  linkedRequests
});

const linkRequests = (request: IEventRequest, prevRequests: IEventRequest[]) => prevRequests.map((prevRequest: IEventRequest) => isSameDaySamePositionRequest(request)(prevRequest) ? {
  ...prevRequest,
  linkedRequests: prevRequest.linkedRequests ?
    [...prevRequest.linkedRequests, makeRequestObj(request)] :
    [makeRequestObj(request)],
} : prevRequest);

export const getPersonsRequests = (personId: number | null, own: boolean) => (requests: IEventRequest[]): IEventRequest[] => filter((request: IEventRequest) => own ?
  request.personId === personId :
  request.personId !== personId,
)(requests);

export const statusToName = ({personName, confirmed}: IEventRequest) => !personName ?
  'Open' : (
    !!personName && !confirmed ?
      `${personName}?` :
      personName
  );

export const statusToColor = ({personName, confirmed}: IEventRequest) => !personName ?
  'red.60' : (
    !!personName && !confirmed ?
      'yellow.60' :
      undefined
  );

export const getPersonsOpenRequests = (personId: number | null, own: boolean) => !personId ? () => [] : flow(
  getPersonsRequests(personId, own),
  filter((request: IEventRequest) => !request.confirmed),
);

export const getPersonsConfirmedRequests = (personId: number | null, own: boolean) => !personId ? () => [] : flow(
  getPersonsRequests(personId, own),
  filter((request: IEventRequest) => request.confirmed),
);

export const filterByDay = (eventDay: string) => (requests: IEventRequest[]) => filter((request: IEventRequest) => request.eventDay === eventDay)(requests);

const isSameDaySamePositionRequest = (request: IEventRequest) => (prevRequest: IEventRequest) => positionsSpanServices.includes(request.positionId) && prevRequest.eventDay === request.eventDay && prevRequest.positionId === request.positionId && request.personId === prevRequest.personId;

const hasSameDaySamePositionRequest = (request: IEventRequest, prevRequests: IEventRequest[]) => prevRequests.filter(isSameDaySamePositionRequest(request)).length > 0;

export const mergeRequests = (prevRequests: IEventRequest[], request: IEventRequest) => {
  if (!prevRequests.length) return [request];
  if (hasSameDaySamePositionRequest(request, prevRequests)) return linkRequests(request, prevRequests);
  return [...prevRequests, request];
};

export const filterForKeyPositions = flow(
  filter((request: IEventRequest) => !notKeyServices.includes(request.positionId) || !!request.personId),
);

export const filterForEventDay = (requests: IEventRequest[]) => (eventDays: string[]): IEventRequest[] => filter((request: IEventRequest) => eventDays.includes(request.eventDay))(requests);

export const makeTimesList = (locales?: Intl.LocalesArgument) => (request: IEventRequest) => flow(
  get('linkedRequests'),
  concat(request),
  compact,
  map(flow(
    get('eventStartDate'),
    toLocaleString(locales, timeOptions),
  )),
  join(' & '),
)(request)

export const mapServiceDataIntoEventServices = (event: IEventChurchToolsApi): IEventRequest[] => flow(
  get('eventServices'),
  map((request: IEventServiceChurchToolsApi) => ({
    // IEvent attributes
    eventId: event.id,
    eventName: event.name,
    eventDescription: event.description,
    eventStartDate: new Date(event.startDate),
    eventDay: event.startDate.substring(0, 10),

    // IRequest attributes
    requestId: request.id,
    confirmed: request.agreed,
    requestedDate: new Date(request.requestedDate),

    // IEventRequest attributes
    personName: request.name,
    personId: request.personId,
    positionId: request.serviceId,
    positionGroupId: event.serviceGroupId,
    positionName: request.service,
    isKeyPosition: !notKeyServices.includes(request.serviceId),
  })),
)(event);

interface AcceptRequestBody {
  personId: number;
}  

interface DeclineRequestBody {
  personId: number;
  comment: string;
}

const sendAcceptRequest = (body: AcceptRequestBody, token?: string) => async (request: IRequest) => {
  try {
    await API.put(CT_API_NAME, '/handle-requests', {
      body: {
        ...body,
        requestId: request.requestId,
      },  
      headers: {
        accept: 'application/json',
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`,
      },
    });  

  } catch (error) {
    if (!isAxiosError(error)) throw error;
    const { response } = (error as AxiosError);
    console.error('Error handling accept via ChurchTools API', response);
    return {
      data: response?.data,
      status: response?.status,
    }   
  }  
};  

const sendDeclineRequest = (body: DeclineRequestBody, token?: string) => async (request: IRequest) => {
  try {
    await API.del(CT_API_NAME, '/handle-requests', {
      body: {
        ...body,
        requestId: request.requestId,
      },
      headers: {
        accept: 'application/json',
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`,
      },
    });

  } catch (error) {
    if (!isAxiosError(error)) throw error;
    const { response } = (error as AxiosError);
    console.error('Error handling decline via ChurchTools API', response);
    return {
      data: response?.data,
      status: response?.status,
    } 
  }
};

const handleLinkedRequests = async ({linkedRequests}: IEventRequest, caller: (request: IRequest) => void) => {
  if (!linkedRequests) return;
  for (let index = 0; index < linkedRequests.length; index++) {
    const linked = linkedRequests[index];
    await caller(linked);
  }
}

export const apiAcceptRequest = async (request: IEventRequest, token?: string) => {
  const {personId} = request;
  if (!personId) return;
  const body = {personId};
  await sendAcceptRequest(body, token)(request);
  await handleLinkedRequests(request, sendAcceptRequest(body, token));
};

export const apiDeclineRequest = async (
  request: IEventRequest,
  comment: string,
  token?: string
) => {
  const {personId} = request;
  if (!personId) return;
  const body = { personId, comment };
  await sendDeclineRequest(body, token)(request);
  await handleLinkedRequests(request, sendDeclineRequest(body, token));
};

const fixDatesInLinkedRequest = ({
  eventStartDate,
  requestedDate,
  ...rest
}: IRequest) => ({
  ...rest,
  eventStartDate: new Date(eventStartDate),
  requestedDate: new Date(requestedDate),
});

export const fixDatesInRequest = ({
  eventStartDate,
  requestedDate,
  linkedRequests,
  ...rest
}: IEventRequest) => ({
  ...rest,
  eventStartDate: new Date(eventStartDate),
  requestedDate: new Date(requestedDate),
  linkedRequests: linkedRequests?.map(fixDatesInLinkedRequest),
});

export type ApiChurchToolsEventData = (
  logout: (setState: Dispatch<SetStateAction<string>>) => void,
  log?: (...args: any) => void,
) => void;

export const getCtApiEvents = async (personId: number, token?: string) => {
  const result = await API.get(CT_API_NAME, '/events', {
    headers: {
      accept: 'application/json',
      'Content-Type': 'application/json',
      Authorization: `Bearer ${token}`,
    },
    queryStringParameters: {personId},
  });
  return result;
}

export const getCtApiNextSunday = async (personId: number, token?: string) => {
  const result = await API.get(CT_API_NAME, '/next-sunday', {
    headers: {
      accept: 'application/json',
      'Content-Type': 'application/json',
      Authorization: `Bearer ${token}`,
    },
    queryStringParameters: {personId},
  });
  return result;
}
