import Dexie, { Table } from "dexie";
import "dexie-observable";
import "dexie-syncable";
import { DATA_PROTOCOL_NAME, DATA_PROTOCOL_URL, dataSyncProtocol } from "./data-sync-protocol";
import { IEventRequest } from "../../helpers/sunday";
import { Duration } from "../../helpers/functional";

Dexie.Syncable.registerSyncProtocol(DATA_PROTOCOL_NAME, dataSyncProtocol);

export class ChurchToolsDB extends Dexie {
  events!: Table<IEventRequest>;
  requests!: Table<IEventRequest>;

  constructor() {
    super('churchToolsDb');
    this.version(1).stores({ events: '&requestId, eventId, personId' });
    this.version(2).stores({ requests: '&requestId, eventId, personId' });
  }
}

export const ctDb = new ChurchToolsDB();

ctDb.syncable.connect(DATA_PROTOCOL_NAME, DATA_PROTOCOL_URL, {
  pollingInMilliseconds: Duration.seconds(30),
});
// (async () => {
//   await ctDb.syncable.disconnect(DATA_PROTOCOL_URL)
//   console.log(`[${new Date().toISOString()}] database disconnected`);
// })()

ctDb.syncable.on('statusChanged', (newstatus, url) => {
  console.log (`[${new Date().toISOString()}]`, "Sync Status changed: " + Dexie.Syncable.StatusTexts[newstatus]);
});