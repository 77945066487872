import { AxiosError, isAxiosError } from "axios";
import { LoggerType } from "../context/aux/logging";
import { Dispatch, SetStateAction } from "react";

type ChurchToolsErrorMessage = {
  data: {
    message: string;
    translatedMessage: string;
  };
}

export const handleError = (error: unknown, msg: string, logout: (setState: Dispatch<SetStateAction<string>>) => void, logger: LoggerType) => {
  if (!isAxiosError(error)) throw error;
  const { response } = (error as AxiosError);
  if (response?.status === 401) logout(() => {});
  try {
    logger.error(msg, (response as ChurchToolsErrorMessage).data.translatedMessage);
  } catch (innerError) {
    logger.error(msg, (error as AxiosError));
  }
  return {
    data: response?.data,
    status: response?.status,
  } 
}