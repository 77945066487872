import { ApplyRemoteChangesFunction, ISyncProtocol, ReactiveContinuation } from "dexie-syncable/api";
import { LoggerFunction, createLogger } from "../aux/logging";
import { IDatabaseChange } from "dexie-observable/api";
import { Duration } from "../../helpers/functional";
import { ctDb } from "./db";
import { IEventChurchToolsApi, IEventRequest, getCtApiEvents, getCtApiNextSunday, mapServiceDataIntoEventServices, mergeRequests } from "../../helpers/sunday";
import { getTokenWithoutProvider, getUserDataWithoutProvider } from "../auth/AuthProvider";
import { flatten, flow, map, reduce, sortBy } from "lodash/fp";
import { handleError } from "../../helpers/handle-errors";
import { DatabaseChangeType, createChangeSet } from "./change-sets";

export const DATA_PROTOCOL_NAME = 'churchToolsDataSync';
export const DATA_PROTOCOL_URL = 'https://msk.cabcookie.de/home';

interface ISyncProtocolOptions {
  pollingInMilliseconds?: number;
}
type TDatabaseChangeType = 1 | 2 | 3;
type ApplyChangeFunction = (change: IDatabaseChange, currentTime: Date) => void;

class ErrorApplyingChanges extends Error {
  change: IDatabaseChange;

  constructor(
      message: string,
      change: IDatabaseChange,
  ) {
    super(message);
    this.name = 'ErrorApplyingChanges';
    this.change = change;
  }; 
};

/**
 * Calls the ChurchTools API and requests all data for a team leader for the
 * next 2 months. It shows all the events planned within this period and which
 * positions are scheduled already and which are open.
 * 
 * @param logout Function that is beeing called when the ChurchTools API returns
 *               that the user is not logged in or the session has been expired.
 * @returns a Promise that when resolved returns an array of event data
 *          (IEventRequest).
 */
const apiChurchToolsEvents = async (logout: () => {}): Promise<IEventRequest[] | undefined> => {
  const logger = createLogger('apiChurchToolsEvents() GET /events', true);
  const {personId} = getUserDataWithoutProvider();
  const token = getTokenWithoutProvider();
  logger('Calling API endpoint...');

  try {
    const result: IEventChurchToolsApi[] = await getCtApiEvents(personId, token);
    logger('result.length', result.length);
    // logger('result', result);

    const mappedData = flow(
      map(mapServiceDataIntoEventServices),
      flatten,
      sortBy('personName'),
      sortBy('eventStartDate'),
      reduce(mergeRequests, []),
    )(result)
    // logger('mapped data', mappedData);
    return mappedData;

  } catch (error) {
    handleError(error, 'Error retrieving events data from ChurchTools API', logout, {info: logger, error: logger, log: logger});
  }
};

const apiChurchToolsRequests = async (logout: () => {}): Promise<IEventRequest[] | undefined> => {
  const logger = createLogger('apiChurchToolsRequests() GET /next-sunday', true);
  const {personId} = getUserDataWithoutProvider();
  const token = getTokenWithoutProvider();
  logger('Calling API endpoint...');

  try {
    const result: IEventChurchToolsApi[] = await getCtApiNextSunday(personId, token);
    logger('result.length', result.length);
    // logger('result', result);

    const mappedData = flow(
      map(mapServiceDataIntoEventServices),
      flatten,
      sortBy('personName'),
      sortBy('eventStartDate'),
      reduce(mergeRequests, []),
    )(result)
    // logger('mapped data', mappedData);
    return mappedData;
      
  } catch (error) {
    handleError(error, 'Error retrieving requests data from ChurchTools API', logout, {info: logger, error: logger, log: logger});
  }
};

const changeTypeToText = (changeType: TDatabaseChangeType): string =>
  changeType === DatabaseChangeType.Create ?
    'Create' :
    changeType === DatabaseChangeType.Update ?
      'Update' :
      'Delete';

const applyEventsChange: ApplyChangeFunction = (change, currentTime) => {
  console.log('applyEventsChange', changeTypeToText(change.type), change);
}

const applyRequestsChange: ApplyChangeFunction = (change, currentTime) => {
  console.log('applyRequestsChange', changeTypeToText(change.type), change);
}

/**
 * For each table we define an apply change function and if needed
 * separate functions for create, update, and delete.
 */
const applyChange: ApplyChangeFunction = (change, currentTime) => {
  const logger = createLogger('applyChange()', true);
  logger('function is not yet implemented as we are not forseeing local changes yet');

  /**
   * TODO: Implement this functionality
   * Mostly we need this for accept/decline of requests, applying absences, as
   * well as for scheduling people (i.e., sending requests to them).
   */
  if (change.table === 'events') return applyEventsChange(change, currentTime);
  if (change.table === 'requests') return applyRequestsChange(change, currentTime);
  throw new Error(`Table '${change.table}' not found.`);
};

/**
 * A function catching the logout call. My hypothesis is, this function gets
 * called if there is a bug in the software or when the user is logged out
 * already. Therfore, we just send a message to the console and create a TODO
 * for future improvement of this functionality.
 */
const logout = () => createLogger('logout()', true)('API encourages to log out the user');

type CtApiFunction = (logout: () => {}) => Promise<IEventRequest[] | undefined>;

const createDataChangeSet = async (
  table: string,
  apiFn: CtApiFunction,
  logger: LoggerFunction,
  logout: () => {},
): Promise<IDatabaseChange[]> => {

  logger(`Fetch ${table} data...`);
  const data: IEventRequest[] | undefined = await apiFn(logout);
  if (!data || data.length === 0) return [];
  const localData = await ctDb.table(table).toArray();
  const dataChanges = createChangeSet(table, data, localData, logger);
  return dataChanges;
}

// const createEventDataChangeSet = async (): Promise<IDatabaseChange[]> => {
//   const logger = createLogger('dataSyncProtocol createEventDataChangeSet()', true);
//   const result = await createDataChangeSet('events', apiChurchToolsEvents, logger, logout);
//   return result;
// }

/**
 * Pulling the current data set for the current person from the ChurchTools API
 * (i.e., our proxy) and then comparing the data with the current database to 
 * create a change set Dexie understands.
 * 
 * @param currentTime Is used as a revision identifier.
 * @param applyFn The callback Dexie uses to apply the remote changes to the
 *                local database.
 */
const createAndApplyRemoteChanges = async (
  currentTime: Date | undefined,
  applyFn: ApplyRemoteChangesFunction
) => {
  const logger = createLogger('createAndApplyRemoteChanges', true);

  const eventDataChanges: IDatabaseChange[] = await createDataChangeSet(
    'events',
    apiChurchToolsEvents,
    createLogger('dataSyncProtocol createDataChangeSet() Events', true),
    logout
  );
  const requestDataChanges: IDatabaseChange[] = await createDataChangeSet(
    'requests',
    apiChurchToolsRequests,
    createLogger('dataSyncProtocol createDataChangeSet() Requests', true),
    logout
  );

  const changes: IDatabaseChange[] = [...eventDataChanges, ...requestDataChanges];
  logger('the changes', changes);
  if (changes.length > 0) applyFn(changes, currentTime);

}

export const dataSyncProtocol: ISyncProtocol = {
  /**
   * We initialize the sync mechanism with this function. It will call the
   * ChurchTools API (i.e., our API proxy) to sync server and client initially
   * and sets up a polling mechanism as well as provides callback functions to
   * call for the next changes.
   * 
   * @param _context Ignored by the protocol.
   * @param _url Ignored by the protocol.
   * @param options A list of options defined by `ISyncProtocolOptions`.
   * @param _baseRevision Ignored by the protocol.
   * @param _syncedRevision Ignored by the protocol.
   * @param changes A list of changes to apply in ChurchTools in the format of
   *                `IDatabaseChange[]`.
   * @param partial This will most likely be false as we are not expecting
   *                many changes in one go.
   * @param applyRemoteChanges Dexie provides this as a hook which we use to
   *                           push the changes from the server to the local
   *                           database. 
   * @param onChangesAccepted Once all local changes have been successfully
   *                          applied on the server, we will call this hook to
   *                          let Dexie know, the changes are in sync with the
   *                          server.
   * @param onSuccess We will call this hook when all changes have been
   *                  successfully processed and let Dexie know the mechanism
   *                  for syncing. Our aim is to create our own polling
   *                  mechanism through which we will call `applyRemoteChanges`
   *                  whenever there has been a change and let Dexie call a
   *                  reactive function whenever something changed locally.
   * @param onError If the sync wasn't successful we let Dexie know via this
   *                hook.
   */
  sync(
    _context,
    _url,
    options: ISyncProtocolOptions,
    _baseRevision,
    _syncedRevision,
    changes,
    partial,
    applyRemoteChanges,
    onChangesAccepted,
    onSuccess,
    onError
  ) {

    const continuation: ReactiveContinuation = {
      /**
       * Applies the local changes to the remote database.
       * 
       * @param changes A list of changes to apply in ChurchTools in the format of
       *                `IDatabaseChange[]`.
       * @param _baseRevision Ignored by the protocol
       * @param partial This will most likely be false as we are not expecting
       *                many changes in one go.
       * @param onChangesAccepted Once all local changes have been successfully
       *                          applied on the server, we will call this hook to
       *                          let Dexie know, the changes are in sync with the
       *                          server.
       */
      react: (changes, _baseRevision, partial, onChangesAccepted) => {
        const currentTime = new Date();

        try {
          for (let index = 0; index < changes.length; index++) {
            const change = changes[index];
            applyChange(change, currentTime);
          }
          onChangesAccepted();
          createAndApplyRemoteChanges(currentTime, applyRemoteChanges);

          const refreshInterval = setInterval(() => createAndApplyRemoteChanges(undefined, applyRemoteChanges), options.pollingInMilliseconds || Duration.minutes());
          ctDb.on('close', () => clearInterval(refreshInterval));

        } catch (error) {
          if((error as Error).name === 'ErrorApplyingChanges') {
            const changeError = error as ErrorApplyingChanges;
            onError(new Error(`Error applying change to database: ${changeError.change}`), Duration.seconds(10));
            return;
          }
          throw error;
        }
      },
      disconnect: () => {},
    };

    continuation.react(changes, _baseRevision, partial, onChangesAccepted);
    onSuccess(continuation);
  },
  partialsThreshold: 100,
}
