import { Tabs, TabItem, Alert } from '@aws-amplify/ui-react';
import { FC, useState } from 'react';
import { FaHome, FaRandom, FaSignOutAlt } from "react-icons/fa";
import { HomePage } from './HomePage';
import { TeamPage } from './TeamPage';
import { useAuth, withAuth } from '../context/auth/AuthProvider';
import { useNavigate } from 'react-router-dom';
import { logger } from '../context/aux/logging';
import { ProfilePage } from './ProfilePage';

interface MainPageProps {
  initialTab?: number;
}

export const MainPage: FC<MainPageProps> = withAuth(({initialTab = 0}) => {
  const [tab, setTab] = useState(initialTab);
  const [errorMsg, setErrorMessage] = useState('');
  const { logout } = useAuth();
  const navigate = useNavigate();

  const navigateHome = () => {
    logger.info('Navigating to HomePage');
    navigate('/home');
    setTab(0);
  }
  
  const navigateTeam = () => {
    logger.info('Navigating to TeamPage');
    navigate('/team');
    setTab(1);
  }

  const navigateProfile = () => {
    logger.info('Navigating to ProfilePage');
    navigate('/profile');
    setTab(2);
  }

  const handleClick = (val: string | number) => {
    logger.info('handleClick event...');
    if (`${val}` === '0') navigateHome();
    if (`${val}` === '1') navigateTeam();
    if (`${val}` === '2') navigateProfile();
    if (`${val}` === '3') logout(setErrorMessage);
  }
    
  return (<>
    <Tabs
      spacing='equal'
      currentIndex={tab}
      onChange={handleClick}
    >
      <TabItem fontSize="small" title={<>
        <FaHome /> Home
      </>}>
        <HomePage />
      </TabItem>
      <TabItem fontSize="small" title={<>
        <FaRandom /> My Team
      </>}>
        <TeamPage />
      </TabItem>
      <TabItem fontSize="small" title={<>
        <FaRandom /> Profile
      </>}>
        <ProfilePage />
      </TabItem>
      <TabItem fontSize="small" title={<>
        <FaSignOutAlt /> Logout
      </>}>Test</TabItem>
    </Tabs>
    {errorMsg && <Alert margin='medium' title='Error' variation='error'>{errorMsg}</Alert>}
  </>)
});
