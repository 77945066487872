import { flow, join, map } from "lodash/fp";
import { makeString } from "../../helpers/functional";

export type LoggerType = {
  info: (...args: any[]) => void;
  error: (...args: any[]) => void;
  log: (...args: any[]) => void;
  debug?: (...args: any[]) => void;
}

export const logger: LoggerType = {
  info: console.log,
  error: console.error,
  log: console.log,
};

export type LoggerFunction = (...args: any[]) => any;

export const createLogger = (label: string, withTime?: boolean) => (...args: any[]) => {
  console.log(
    withTime ? `[${new Date().toISOString()}]` : '',
    label,
    ...args
  );
  if (args.length > 1) return;
  return args[0];
}

export const loggerWithSetState = (setState: (val: string) => void) => {
  const loggerHelper = (type: 'info' | 'error' | 'log' | 'debug' = 'log') => (...args: any[]) => {
    console[type](...args);
    flow(
      map(makeString),
      join(' '),
      setState,
    )(args);
  };

  return {
    info: loggerHelper('info'),
    error: loggerHelper('error'),
    log: loggerHelper('log'),
  };
}