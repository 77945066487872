import { Card, Collection, Grid, Heading, Text, View } from "@aws-amplify/ui-react";
import { IEventRequest } from "../helpers/sunday";
import { filter, flow, get, join, map, uniq } from "lodash/fp";
import { shortDateOptions, timeOptions, toLocaleString } from "../helpers/functional";
import { useState } from "react";
import { ExpandIcon } from "./ExpandIcon";
import { useLanguage } from "../context/aux/LanguageContext";

export interface GroupedEventDetails {
  eventStartDate: Date;
  eventName: string;
  eventDescription: string;
}

export interface GroupEventDaysWithDetails {
  eventDay: string;
  eventDetails: GroupedEventDetails[];
}

interface EventTeamCardProps {
  event: GroupEventDaysWithDetails;
  requests: IEventRequest[];
}

export const EventTeamCard = ({event, requests}: EventTeamCardProps) => {
  const { language } = useLanguage();
  const [detailsExpanded, setDetailsExpanded] = useState(false);

  return (
    <Card
      paddingBottom='medium'
      borderRadius="small"
      borderColor="lightgray"
      borderWidth="1px"
    >
      {/* Event information */}
      <View paddingBottom='medium'>
        <Heading level={6}>
          {flow(
            get('eventDetails'),
            get(0),
            get('eventStartDate'),
            toLocaleString(language, shortDateOptions),
          )(event)}, {flow(
            get('eventDetails'),
            map(({eventStartDate}: GroupedEventDetails): Date => eventStartDate),
            map(toLocaleString(language, timeOptions)),
            join(' & '),
          )(event)}
        </Heading>
        <Text fontSize='small'>
          {flow(
            get('eventDetails'),
            map(get('eventName')),
            join(' & '),
          )(event)}
        </Text>
        <Text fontSize='small'>
          {flow(
            get('eventDetails'),
            map(get('eventDescription')),
            uniq,
          )(event)}
        </Text>
      </View>

      {/* Position information */}
      <Collection
        type="list"
        paddingBottom='large'
        items={requests.filter(({
          isKeyPosition,
          personName
        }: IEventRequest) => isKeyPosition || !!personName)}
      >
        {({
          positionName,
          personName,
          confirmed,
          eventStartDate,
          linkedRequests,
        }, index) => (
          <Text
            key={index}
            color={!personName ? 'red.60' : !confirmed ? 'yellow.80' : undefined}
            fontWeight={!personName || !confirmed ? 'bold' : undefined}
          >
            <b>
              {toLocaleString(language, timeOptions)(eventStartDate)}
              {linkedRequests && linkedRequests.length > 0 && ' & '}
              {flow(
                map(({eventStartDate}) => eventStartDate),
                map(toLocaleString(language, timeOptions)),
                join(' & '),
              )(linkedRequests)}
            </b>
            : {positionName}{' – '}
            {!personName ? 'Open' : personName}{!!personName && !confirmed ? '?' : ''}
          </Text>
        )}
      </Collection>

      <View onClick={() => setDetailsExpanded(!detailsExpanded)}>
        <Grid templateColumns='1fr 20fr' alignItems='flex-start'>
          <View><ExpandIcon expanded={detailsExpanded} /></View>

          <Text fontSize='small' isTruncated>
            Show open non-key positions: {!detailsExpanded && flow(
              filter(({
                isKeyPosition,
                personName,
              }: IEventRequest) => !isKeyPosition && !personName),
              map(({
                positionName,
                eventStartDate,
                linkedRequests,
              }: IEventRequest) => `${toLocaleString(language, timeOptions)(eventStartDate)}${linkedRequests && linkedRequests.length > 0 ? ' & ' : ''}${flow(
                map(({eventStartDate}) => eventStartDate),
                map(toLocaleString(language, timeOptions)),
                join(' & '),
              )(linkedRequests)}: ${positionName}`),
              join(', '),
            )(requests)}
          </Text>

          {detailsExpanded && flow(
            filter(({
              isKeyPosition,
              personName,
            }: IEventRequest) => !isKeyPosition && !personName),
            arr => arr.map(({
              positionName,
              eventStartDate,
              linkedRequests,
            }: IEventRequest, index: number) => (
              <Text key={index} fontSize='small' column={2} marginTop='xs'>
                {toLocaleString(language, timeOptions)(eventStartDate)}
                {linkedRequests && linkedRequests.length > 0 && ' & '}
                {flow(
                  map(({eventStartDate}) => eventStartDate),
                  map(toLocaleString(language, timeOptions)),
                  join(' & '),
                )(linkedRequests)}
                : {positionName}
              </Text>
            )),
          )(requests)}

        </Grid>
      </View>
   </Card>
  );
}