import { Card, Collection, Heading, View } from "@aws-amplify/ui-react";
import { IEventRequest, eventWithin7Days, filterForKeyPositions, getPersonsRequests, makeEventObj } from "../helpers/sunday"
import { EventCard } from "./EventCard"
import { EventTitle } from "./EventTitle";
import { filter, flow, get } from "lodash/fp";
import { makeFunctionalLogger } from "../helpers/functional";
import { logger } from "../context/aux/logging";

interface NextEventProps {
  requests: IEventRequest[];
  title?: string;
  currentUserPersonId?: number;
}

export const NextEvent = ({title = 'Next event', requests, currentUserPersonId}: NextEventProps) => {
  const logFn = makeFunctionalLogger(logger.info);

  const filteredRequests = flow(
    logFn('Requests'),
    filter(eventWithin7Days),
    logFn('Filtered'),
  )(requests)

  return filteredRequests.length > 0 ? (
    <View paddingBottom='medium'>
      <Heading level={5} paddingBottom='small'>{title}</Heading>
      <Card
        paddingBottom='medium'
        borderRadius="small"
        borderColor="lightgray"
        borderWidth="1px"
      >
        <EventTitle event={flow(
          logFn('EventTitle Requests'),
          getPersonsRequests(currentUserPersonId || null, true),
          logFn('EventTitle Requests of Person'),
          get(0),
          logFn('EventTitle'),
          makeEventObj,
        )(filteredRequests)} />
        <Collection type="list" items={filterForKeyPositions(filteredRequests)} marginBottom='medium'>
          {(request: IEventRequest, index) => (
            <EventCard key={index} request={request} currentUserPersonId={currentUserPersonId} />
          )}
        </Collection>
      </Card>
    </View>
  ) : <></>
}
