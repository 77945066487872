import { API } from "aws-amplify";
import { flow, map } from "lodash/fp";
import { Dispatch, SetStateAction } from "react"
import { handleError } from "./handle-errors";
import { logger } from "../context/aux/logging";
import { CT_API_NAME } from "./api";

export const isDevEnvironment = () => ['development', 'test'].includes(process.env.NODE_ENV);
// export const isDevEnvironment = () => false;

export interface IAvailability {
  absenceId: number;
  comment: string;
  absenceReasonId: number;
  absenceReasonName: string;
  startDate: Date;
  endDate: Date;
}

type ApiChurchToolsAbsences = (
  setAbsences: Dispatch<SetStateAction<IAvailability[]>>,
  userId: number | null,
  logout: (setState: Dispatch<SetStateAction<string>>) => void,
) => void;

const fixDates = ({startDate, endDate, ...rest}: IAvailability) => ({
  ...rest,
  startDate: new Date(startDate),
  endDate: new Date(endDate),
})

export const apiChurchToolsAbsences: ApiChurchToolsAbsences = async (setAbsences, userId, logout) => {
  if (!userId) return;
  if (isDevEnvironment()) {
    flow(
      map(fixDates),
      setAbsences,
    )(require('./example-absences.json'));
    return;
  }

  try {
    const result: IAvailability[] = (await API.get(CT_API_NAME, '/absences', {
      headers: {
        accept: 'application/json',
        'Content-Type': 'application/json',
      },
      response: true,
      withCredentials: true,
      queryStringParameters: {personId: userId},
    })).data;
    // console.log("Result", result);

    flow(
      map(fixDates),
      setAbsences,
    )(result);
    
  } catch (error) {
    return handleError(error, 'Error retrieving request data from ChurchTools API', logout, logger)
  }
}
