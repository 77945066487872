import { ReactNode, createContext, useContext, useEffect, useState } from "react";

type AuthContextType = {
  language: string;
  setLanguage: (language: string) => void;
};

const defaultLanguage = 'en-GB';
export const LanguageContext = createContext<AuthContextType>({
  language: defaultLanguage,
  setLanguage: () => {},
});

export const useLanguage = () => useContext(LanguageContext);

interface LanguageProviderProps {
  children?: ReactNode;
}

export const LanguageProvider = ({ children }: LanguageProviderProps) => {
  const [language, setLanguage] = useState(defaultLanguage);

  useEffect(() => {
    if (navigator && navigator.language) {
      setLanguage(navigator.language);
    }
  }, []);

  return (
    <LanguageContext.Provider value={{ language, setLanguage }}>
      {children}
    </LanguageContext.Provider>
  );
}
