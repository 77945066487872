import { Flex, View } from "@aws-amplify/ui-react";
import { RequestsCollection } from "../components/RequestsCollection";
import { NextEvent } from "../components/NextEvent";
import { useAuth } from "../context/auth/AuthProvider";
import { useLiveQuery } from "dexie-react-hooks";
import { ctDb } from "../context/data/db";
import { createLogger } from "../context/aux/logging";
import { sortBy } from "lodash";

export const HomePage = () => {
  const logger = createLogger('HomePage', true);
  const {personId} = useAuth().getUserData();

  const requests = useLiveQuery(async () => {
    const result = await ctDb.requests.toArray();
    logger('useLiveQuery()', 'result', result);
    return sortBy(result, 'eventStartDate', 'positionId');
  });

  return (
    <Flex direction="column" gap="1rem">
      <View padding="medium">
        <NextEvent title="Next event" requests={requests || []} currentUserPersonId={personId} />
        <RequestsCollection
          confirmed={false}
          requests={requests || []}
          currentUserPersonId={personId}
          title="Awaiting your response"
        />
        <RequestsCollection
          confirmed={true}
          requests={requests || []}
          currentUserPersonId={personId}
          title="Your schedule"
        />
      </View>
    </Flex>
  )
}
